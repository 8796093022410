import React from "react";
import Image from "next/image";
import Separator from "../public/separator.svg";
import Link from "next/link";

interface FullScreenFeedbackProps {
  title: string;
  bodyText: string;
  buttonText: string;
}

export const FullScreenFeedback: React.FC<FullScreenFeedbackProps> = ({
  title,
  bodyText,
  buttonText,
}) => {
  return (
    <div className="flex h-screen items-center justify-center bg-black-navy p-6">
      <div className="flex max-w-[800px] grow flex-col items-start text-white">
        <div className="mb-6 font-libre-baskerville text-4xl font-bold">
          {title}
        </div>
        <div className="mb-8 whitespace-pre-line font-light leading-relaxed tracking-wide">
          {bodyText}
        </div>
        <div className="mb-8 flex">
          <Image src={Separator} className="w-fit" alt="" />
        </div>
        <Link href="/">
          <button className="bg-clear text-md mx-auto block border border-white py-2 px-6 font-light uppercase">
            {buttonText}
          </button>
        </Link>
      </div>
    </div>
  );
};
