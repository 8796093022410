import React from "react";
import Navbar from "../components/Navbar";
import { GetStaticProps } from "next";
import { useTranslations } from "next-intl";
import { FullScreenFeedback } from "../components/FullScreenFeedback";
import { shouldDisplayChineseLocale } from "../util/service";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const _locale = shouldDisplayChineseLocale(locale) ? "zh" : locale;
  return {
    props: {
      messages: (await import(`../locales/${_locale}/common.json`)).default,
    },
    revalidate: 60,
  };
};

const Custom404 = () => {
  const t = useTranslations("404");
  return (
    <>
      <Navbar />
      <FullScreenFeedback
        title={t("title")}
        bodyText={t("bodyText")}
        buttonText={t("buttonText")}
      />
    </>
  );
};

export default Custom404;
